@font-face {
  font-family: "Arcade";
  src: url("./arcade.ttf");
}

body, html {
  background: black;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;

  & > div {
    height: 100%;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;

      & > div {

        display: flex;

        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.controls {
  &.active {
    display: flex;
    min-height: 100vh;
    min-width: 100vw;
    max-height: 100vh;
    max-width: 100vw;

    > div {
      min-width: 100vw;
      display: flex;
      justify-content: space-between;
    }
  }

  display: none;
  flex-direction: column;
  z-index: 100;
  background: transparent;
  min-height: 0;
  min-width: 0;
  max-height: 0;
  max-width: 0;

  > div {
    min-width: 0;
    display: none;
  }
}

.video {
  flex: 1;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;

  & > div {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }

  iframe {
    width: 100vw;
    height: 100vh;
  }
}

.blue, .green, .red, .yellow {
  background: transparent;
  width: 50vw;
  height: 50vh;
  cursor: grab;
  border: 0;
}

.placeholder {
  min-width: 25vw;
  min-height: 20vh;
}

.extra {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50vw;
  height: 20vh;
  align-items: center;

  a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 400%;
    font-family: "Arcade", monospace;
    margin-bottom: 2rem;
    padding: 1rem;
    border: solid 1px white;
    background: black;
  }

}